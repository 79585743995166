export default {
    days: [
        { id: null, name: '' },  
        { id: 1, name: 'SEG' },
        { id: 2, name: 'SEG/TER' },
        { id: 3, name: 'QUA' },
        { id: 4, name: 'TER/QUI' },
        { id: 5, name: 'QUI/SEX' },
        { id: 6, name: 'SEG/SEX' },
        { id: 7, name: 'SEX' },
        { id: 8, name: 'SÁB' },
        { id: 9, name: 'SEX/SÁB' },
      ],
      shifts: [
        { id: 1, name: 'Matutino' },
        { id: 2, name: 'Vespertino' },
        { id: 3, name: 'Noturno' },
        { id: 4, name: 'Integral' },
        { id: 5, name: 'Digital' },
      ],
      gridModalities: [
        { id: null, name: '' },
        { id: 3, name: 'Presencial' },
        { id: 4, name: 'Híbrido' },
        { id: 2, name: 'Digital Assíncrono' },
        { id: 1, name: 'Digital Síncrono' },
        // { id: 5, name: 'Digital Personalizável' },
      ],
      joinCategories: [
        { id: null, name: '' },
        { id: 1, name: 'Local' },
        { id: 2, name: 'Regional' },
        { id: 3, name: 'Nacional' },
      ],
      baseGroupStatuses: [
        { id: 1, name: 'Ativa' },
        { id: 2, name: 'Remanejada' },
        { id: 3, name: 'Encerrada' },
        { id: 4, name: 'Cancelada' },
      ],
      observations: [
        { id: null,                           name: null },
        { id: 'Concordo',                     name: 'Concordo' },
        { id: 'Concordo - Alteração Destino', name: 'Concordo - Alteração Destino' },
        { id: 'Não Concordo',                 name: 'Não Concordo' },
        { id: 'Indicação Gestor',             name: 'Indicação Gestor' }
      ],
}